<template>
  <div class="setting-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.setting-header {
  font-size: 20px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 16px;
}
</style>
